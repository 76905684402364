import React from "react";

export default function PageBanner({text}) {
  return (
    <div className="text-center bg-card-semi-opaque pt-5">
      <div className="circular_image ">
        <img src="/cubeV2colorTop.svg" alt="cube logo" />
      </div>
      <div className="pt-10 pb-12">
        <div className="text-2xl font-bold text-primary">{text}</div>
      </div>
    </div>
  );
}
