import { createSlice } from "@reduxjs/toolkit";

export const userLoggedInSlice = createSlice({
  name: "loggedin",
  initialState: {
    loggedin: false,
  },
  reducers: {
    set_logged_in: (state, action) => {
      state.loggedin = action.payload;
    }
  }
});

export const { set_logged_in } = userLoggedInSlice.actions;

export default userLoggedInSlice.reducer;