import React from 'react'
import './SiteLogo.css'

export default function SiteLogo() {
    return (
        <>
            <img className="logo pb-1 mr-1" src="/logo.svg" alt="logo" />
        </>

    )
}
