import React from "react";
import { Link } from "react-router-dom";

function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function FrontPageLink({ link, title }) {
  return (
    <Link to={link}>
      <div className="m-1">
        <div className="bg-card-semi-opaque">
          <div className="card-body text-center p-12">
            <div className="text-primary text-2xl">{Capitalize(title)}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}
