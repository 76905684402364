import React from 'react'
import TSCsAndCs from '../components/TSCsAndCs';
import configData from '../config/default.json'
import { motion } from "framer-motion";
import PageBanner from '../components/card/PageBanner';


export default function TSandCs() {

  const pageMotion = configData.pageMotionMenu

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      { /* container */}
      <div className="m-5">



            { /* card - page banner */}
            <PageBanner text={"Terms & Conditions of Use"} />



            <div className="">
              <TSCsAndCs />
            </div>


      </div>
    </motion.div>
  );
}
