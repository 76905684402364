import React from 'react'
import SubscriptionStatus from '../components/SubscriptionStatus'
import PageBanner from "../components/card/PageBanner";

export default function Subscription() {
  return (
    <div className="container-lg my-5">
      <div className="row gy-3">
        <div className="col ">

          { /* card - page banner */}
          <PageBanner text={"Subscription Management"} />

          <div className="bg-card-white-opaque p-3 text-center">
            <SubscriptionStatus />
          </div>
        </div>
      </div>
    </div>
  )
}
