
import { useEffect, useState } from "react";
import { db } from '../firebase/FirebaseClient'
import { doc, getDoc } from "firebase/firestore";
import ReactMarkdown from 'react-markdown'
import configData from '../config/default.json'
import { motion } from "framer-motion";
import PageBanner from "../components/card/PageBanner";
import CustomLinks from "../components/ui/CustomLinks";

export default function Guide() {

    const pageMotion = configData.pagepageMotionMenuMotion

    const [articleData, setarticleData] = useState(null)

    useEffect(() => {

        const wellbeingDocRef = doc(db, configData.partials, configData.userGuide)
        const getGuideData = async () => {
            const docSnap = await getDoc(wellbeingDocRef);
            if (docSnap.exists()) {
                setarticleData(docSnap.data().content)
            } else {
                console.log("Error : Guide - No document found!");
            }
        }
        getGuideData()

        window.scrollTo(0, 0)

    }, [])

    return (

        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}
        >
            {/* container */}
            <div className="m-5">

                { /* card - page banner */}
                <PageBanner text={"User Guide"} />

                <div className="bg-card-white-opaque p-3 text-center">
                    {articleData && (
                        <div className="bg-card-white-opaque p-3">

                            <div className=" flex flex-row mx-auto justify-between items-center">
                                <div></div>
                                <article className="prose lg:prose-xl">
                                    <div className="text-center">
                                        <ReactMarkdown
                                            className='react-markDown'
                                            children={articleData}
                                            components={{
                                                a: ({ node, ...props }) => <CustomLinks {...props} />
                                            }}
                                        />
                                    </div>
                                </article>
                                <div></div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </motion.div>
    )
}
