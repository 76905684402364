import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));



// import React from 'react';
// // import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';

// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript


// render react app
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  // document.getElementById('root')
);

serviceWorkerRegistration.register();
reportWebVitals();