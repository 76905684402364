import { db } from '../firebase/FirebaseClient'
import { setDoc, doc, onSnapshot } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import configData from '../config/default.json'

export async function createCheckoutSession(uid) {

  const paymentIntent = uuidv4()
  const paymentRef = doc(db, "users", uid, "checkout_sessions", paymentIntent);
  
  let priceCode = configData.pricecode_dev

  if (process.env.REACT_APP_ENV === 'production') {
    priceCode = configData.pricecode_production
  }

  await setDoc(paymentRef, {
    price: priceCode,
    success_url: window.location.origin,
    cancel_url: window.location.origin
  })
  .catch((err) => {
    console.log('err is : ', err)
  })

  onSnapshot(paymentRef, (snap) => {
    const { error, url } = snap.data();
    if (error) {
      console.log(`An error occured: ${error.message}`);
    }
    if (url) {
      // redirect to Stripe Checkout URL
      window.location.assign(url);
    }
  })
}
