import React from 'react'
import './BadgeSizeable.css'


export default function BadgeSizeable({ sizeOfBadge, titleOfBadge }) {


  let defaultStyle = 'p-5 text-primary'
  let style;

  if (sizeOfBadge >= 10) {
    style = ' node-xl'
  } else if (sizeOfBadge >= 6) {
    style = ' node-lg'
  } else if (sizeOfBadge >= 2) {
    style = ' node-md'

  } else if (sizeOfBadge >= 1) {
    style = ' node-sm'

  } else {
    style = ' node-link'
  }
  let renderedStyle = defaultStyle + style
  
  return (
    <>
      <button className={renderedStyle}>{titleOfBadge}</button>
    </>
  )
}
