import { React, useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { storeSearch } from '../redux-toolkit/searchSlice'
import { db } from '../firebase/FirebaseClient'
import { doc, getDoc } from "firebase/firestore";
import ReactMarkdown from 'react-markdown'
import SearchResults from '../components/SearchResults'
import { storeSearchText } from '../redux-toolkit/searchTextSlice'
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSelector } from "react-redux";

import CustomLinks from "../components/ui/CustomLinks";
import PageBanner from "../components/card/PageBanner";
import configData from '../config/default.json'
import Fuse from 'fuse.js'
import { motion } from "framer-motion";
import SimpleBtn from "../components/ui/SimpleBtn";
import SubmitTextModal from "../components/ui/SubmitTextModal";
import PageLoading from "../components/PageLoading";
import UserStatus from "../components/UserStatus";

export default function AskTheCoach() {

    const functions = getFunctions(getApp());
    const pageMotion = configData.pageMotion

    const [indexData, setIndexData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [error, setError] = useState(null)
    const [textModal, setTextModal] = useState("")
    const [feebackError, setFeedbackError] = useState("")
    const [feebackStatus, setFeedbackStatus] = useState("")
    const [sendingFeedback, setSendingFeedback] = useState(false)
    const [askTheCoachSections, setAskTheCoachSections] = useState([])
    const [userPremium, setUserPremium] = useState(false)

    const { premium } = useSelector((state) => state.premium);

    const dispatch = useDispatch()
    const options = {
        includeScore: true,
        keys: [
            "documentName",
            "parent",
            "title",
            "desc",
            "children",
        ]
    };

    const handleSubmit = event => {
        event.preventDefault(); // 👈️ prevent page refresh
    }

    const inputHandler = (e) => {
        var lowerCase = e.target.value.toLowerCase();
        const fuse = new Fuse(indexData, options);
        const allResults = fuse.search(lowerCase);
        dispatch(storeSearchText(e.target.value));
        const firstTenResults = allResults.slice(0, 10);
        dispatch(storeSearch(firstTenResults))
    };

    useEffect(() => {
        setUserPremium(premium)
    }, [premium])

    useEffect(() => {

        const documentIndexRef = doc(db, configData.collectionName, 'documentIndex')
        const getDocumentIndexData = async () => {
            const docSnap = await getDoc(documentIndexRef);
            if (docSnap.exists()) {
                setIndexData(JSON.parse(docSnap.data().payload))
            } else {
                console.log('Error : AskTheCoach documentIndex - no document found!')
                setError('content not available')
            }
        }
        getDocumentIndexData()

        const askTheCoachDocRef = doc(db, configData.collectionName, configData.askTheCoach)
        const getAskTheCoachData = async () => {
            const docSnap = await getDoc(askTheCoachDocRef);
            if (docSnap.exists()) {
                const result = docSnap.data().content.split(/---*/);
                setAskTheCoachSections(result)
            } else {
                console.log('Error : AskTheCoach - no document found!')
                setError('content not available')
            }
        }
        getAskTheCoachData()

    }, [])

    useEffect(() => {

        const handleFeedback = (text) => {
            setSendingFeedback(true)
            const addMessage = httpsCallable(functions, 'sayHello');
            addMessage({ text: text })
                .then((r) => {
                    if (r.data) {
                        if (r.data.result === 'ok') {
                            setFeedbackStatus("Message has been sent to the coach")
                            setTextModal("")
                            setFeedbackError("")
                            setSendingFeedback(false)
                        }
                    }
                })
                .catch((error) => {
                    setFeedbackError("Sorry, there was a problem sending your message. Please try again later.")
                    console.log("failed : ", error);
                    setSendingFeedback(false)
                });
        }
        if (textModal.length > 0) {
            handleFeedback(textModal)
        }

    }, [textModal, functions])

    return (

        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}
        >
            { /* container */}
            <div className="m-5">


                { /* card - page banner */}
                <PageBanner text={"Ask the Coach"} />

                <div className="text-center pb-5 pt-5">

                    {!userPremium && (
                        <div className="pt-10">

                            <div className="pt-5 font-light text-center">Subscribe to get full access</div>

                            <div className="pt-5">
                                <UserStatus />
                            </div>
                        </div>
                    )}

                    <div className=" flex flex-row mx-auto justify-between items-center">
                        <div></div>
                        <article className="prose lg:prose-xl">
                            <ReactMarkdown
                                className='react-markDown'
                                children={askTheCoachSections[0]}
                                components={{
                                    a: ({ node, ...props }) => <CustomLinks {...props} />
                                }}
                            />
                        </article>
                        <div></div>
                    </div>

                    {(!error) && (
                        <>
                            <form className="form-inline" onSubmit={handleSubmit}>

                                <input onChange={inputHandler}
                                    type="text"
                                    placeholder="Search"
                                    className="p-2 px-4 border shadow placeholder:text-center md:text-left  focus:outline-none"
                                />


                            </form>
                        </>
                    )}

                    <SearchResults />

                    <div className=" flex flex-row mx-auto justify-between items-center">
                        <div></div>
                        <article className="prose lg:prose-xl">
                            <ReactMarkdown
                                className='react-markDown'
                                children={askTheCoachSections[1]}
                                components={{
                                    a: ({ node, ...props }) => <CustomLinks {...props} />
                                }}
                            />
                        </article>
                        <div></div>
                    </div>
                    <div className="text-center p-5">


                        {(!error) && (
                            <SimpleBtn

                                text="Send Feedback"
                                clickHandler={() => {
                                    setShowModal(!showModal)
                                }}
                            />
                        )}
                        {sendingFeedback && <PageLoading className="pt-5" />}
                        {feebackError && <p className="pt-10 text-red-500">{feebackError}</p>}
                        {feebackStatus && <p className="pt-10 text-green-400 font-bold">{feebackStatus}</p>}

                    </div>

                    <div className=" flex flex-row mx-auto justify-between items-center">
                        <div></div>
                        <article className="prose lg:prose-xl">
                            <ReactMarkdown
                                className='react-markDown'
                                children={askTheCoachSections[2]}
                                components={{
                                    a: ({ node, ...props }) => <CustomLinks {...props} />
                                }}
                            />
                        </article>
                        <div></div>
                    </div>
                </div>
            </div>

            {showModal && (
                <SubmitTextModal
                    handleClose={setShowModal}
                    handleText={setTextModal}
                    headingText={"Enter your note to the coach ..."}
                    placeholderText={"Your Feedback"}
                    bodyText={`text limit ${configData.maxFeedback} characters`}
                    textMax={configData.maxFeedback}
                    text={textModal}
                />
            )}
        </motion.div>

    )
}

