import { useState, useEffect } from "react";

export default function Toggle() {

    const [toggle, setToggle] = useState(true);

    const handleClickToggle = () => {
        setToggle(!toggle)
    }
    
    useEffect(() => {
        localStorage.setItem("tips", toggle);
    }, [toggle])

    return (

        <div className="flex justify-center pt-5">
            <label className="label cursor-pointer">
                <span className="label-text text-sm pr-16">Show at start</span>
                <input
                    type="checkbox"
                    value={toggle}
                    className="toggle toggle-primary"
                    onChange={() => handleClickToggle()}
                    checked={toggle}
                />
            </label>
        </div>

    )
}


