import UserStatus from "../components/UserStatus"
import { db } from '../firebase/FirebaseClient'
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react"
import configData from '../config/default.json'
import { useSelector } from 'react-redux'
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import FrontPageLink from "../components/card/FrontPageLink";
import './Home.css'

export default function Home() {

  const [topLevelIndexData, seTopLevelIndexData] = useState(null)
  const pageMotion = configData.pageMotion
  const navigate = useNavigate();
  const [userPremium, setUserPremium] = useState(false)

  const { premium } = useSelector((state) => state.premium);



  useEffect(() => {
    const savedItem = localStorage.getItem("tips");
    const tips = JSON.parse(savedItem);
    if (tips === null) {
      localStorage.setItem("tips", true);
    }
    const showTips = localStorage.getItem("tips");
    if ((showTips === 'true') && (userPremium)) {
      navigate("/tips");
    }
  }, [navigate, userPremium])

  useEffect(() => {

    const documentIndexRef = doc(db, configData.partials, 'documentIndex')

    const getDocumentIndexData = async () => {
      const docSnap = await getDoc(documentIndexRef);
      if (docSnap.exists()) {
        // console.log('Home : Document data found');
        const indexData = JSON.parse(docSnap.data().payload)
        const topLevelItems = indexData.filter(item => {
          if (
            (item.level === 1) &&
            (item.name !== 'Blog') &&
            (item.name !== 'NSTWellbeingCoach') &&
            (item.name !== 'Ask the Coach')
          ) {
            return item;
          } else {
            return null;
          }
        })
        seTopLevelIndexData(topLevelItems)
        // console.log('Home : topLevelItems : [', topLevelItems, ']');
      } else {
        console.log("ERROR : Home - No document found!");
      }
    }
    getDocumentIndexData()

  }, []);



  useEffect(() => {
    setUserPremium(premium)
  }, [premium])


  return (
    <>

      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageMotion}
      >
        { /* container */}
        <div className="m-5">

          {!userPremium && (
            <div className="pt-10">
              <div className="pt-5 font-light text-center">Subscribe to get full access</div>
              <div className="pt-5">
                <UserStatus />
              </div>
            </div>
          )}

          <div className="container-bg">

            {topLevelIndexData && topLevelIndexData.map(item => (
              <div key={item.id} className="">
                <FrontPageLink link={`/wellbeing/${item.id}`} title={item.documentName} />
              </div>
            ))}
            {topLevelIndexData && userPremium && (
              <FrontPageLink link={`/askthecoach`} title={`Ask The Coach`} />
            )}
            <FrontPageLink link={`/blog`} title={`Blog`} />
          </div>
        </div>

      </motion.div>

    </>
  );
}
