import { configureStore } from '@reduxjs/toolkit'
import searcherReducer from './searchSlice'
import searchTextReducer from './searchTextSlice'
// import blogReducer from './blogSlice'
// import userStateReducer from './userStateSlice'
import premiumReducer from './userPremiumSlice'
import loggedInReducer from './userLoggedInSlice'

export const store = configureStore({ 
  reducer: {
    searchtext: searchTextReducer,
    searcher: searcherReducer,
    premium: premiumReducer,
    loggedin: loggedInReducer,
  },
})

