import { useEffect, useState } from "react";
import { db } from '../firebase/FirebaseClient'
import { doc, getDoc } from "firebase/firestore";
import ReactMarkdown from 'react-markdown'
import configData from '../config/default.json'
import CustomLinks from "./ui/CustomLinks";

export default function TSCsAndCs() {

    const [licensceData, setLicensceData] = useState(null)
    const [privacyData, setPrivacyData] = useState(null)

    useEffect(() => {

        const termsDocRef = doc(db, configData.partials, configData.termsandconditions)
        const getTermsAndConditionsData = async () => {
            const docSnap = await getDoc(termsDocRef);
            if (docSnap.exists()) {
                setLicensceData(docSnap.data().content)
            } else {
                console.log("Error : TSCsAndCs Terms - No document found!");
            }
        }
        getTermsAndConditionsData()

        const privacyDocRef = doc(db, configData.partials, configData.privacypolicy)
        const getPrivacyPolicyData = async () => {
            const docSnap = await getDoc(privacyDocRef);
            if (docSnap.exists()) {
                setPrivacyData(docSnap.data().content)
            } else {
                console.log("Error : TSCsAndCs Privacy - No document found!");
            }
        }
        getPrivacyPolicyData()

        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="">
            {licensceData && (
                <div className="bg-card-white-opaque p-3">
                    <div className="">
                        <div className=" flex flex-row mx-auto justify-between">
                            <div></div>
                            <article className="prose lg:prose-xl">
                                <div className="prose lg:prose-xl pt-5 text-4xl">Terms and Conditions</div>

                                <ReactMarkdown
                                    className=''
                                    children={licensceData}
                                    components={{
                                        a: ({ node, ...props }) => <CustomLinks {...props} />
                                    }}
                                />
                            </article>
                            <div></div>
                        </div>
                        <div className=" flex flex-row mx-auto justify-between">
                            <div></div>
                            <article className="prose lg:prose-xl">
                                <div className="prose lg:prose-xl pt-5 pb-5 text-4xl">Privacy Policy</div>
                                <ReactMarkdown
                                    className=''
                                    children={privacyData}
                                    components={{
                                        a: ({ node, ...props }) => <CustomLinks {...props} />
                                    }}
                                />
                            </article>
                            <div></div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
