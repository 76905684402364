import { React, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from 'react-redux'
import SiteLogo from "./SiteLogo";
import './Navbar.css'

const Nav = () => {
    const [expanded, setExpanded] = useState(false)
    const navigate = useNavigate();
    const [userPremium, setUserPremium] = useState(false)
    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const [links, setLinks] = useState(null)
    const { premium } = useSelector((state) => state.premium);
    const { loggedin } = useSelector((state) => state.loggedin);


    
    
    useEffect(() => {
        setUserPremium(premium)
    }, [premium])
    
    useEffect(() => {
        setUserLoggedIn(loggedin)
    }, [loggedin])
    
    useEffect(() => {
        
        const authLinks = [
            { name: "Sign Out", to: "/logout", id: 30 },
        ];
        
        const premiumLinks = [
            { name: "Welcome", to: "/tips", id: 20 },
        ];

        const navLinks = [
            { name: "About", to: "/about", id: 1 },
            { name: "Founder", to: "/founder", id: 2 },
            { name: "User Guide", to: "/guide", id: 3 },
            { name: "FAQ", to: "/faq", id: 4 },
            { name: "Ts&Cs", to: "/tsandcs", id: 5 },
            { name: "Subscription", to: "/subscription", id: 6 },
        ];

        if (userPremium) {
            setLinks([])
            setLinks(premiumLinks.concat(navLinks).concat(authLinks))
        } else if (userLoggedIn) {
            setLinks([])
            setLinks(navLinks.concat(authLinks))
        } else {
            setLinks([])
            setLinks(navLinks)
        }

    }, [userPremium, userLoggedIn])

    // TODO : Check this behaviour
    function handleHomeClicked() {
        setExpanded(false);
        const url = new URL(window.location.href);
        if (url.pathname !== '/') {
            navigate("/");
        }
    }

    return (
        <>
            { /* navbar container */}

            <div className="  ">

                <nav className="p-4 mb-1 text-secondary flex items-center justify-between">

                    { /* logo */}

                    <div className="nav-link" onClick={() => handleHomeClicked()}>
                        <div className="flex row">
                            <SiteLogo />
                            <div className="md:text-lg font-bold alpha">NST <span className="beta">Wellbeing Coach</span></div>
                        </div>
                    </div>

                    { /* navbar links */}

                    <div className="hidden md:flex ">

                        {links && links.map(({ name, to, id }) => (
                            <Link key={id} className="text-primary text-sm p-5 hover:text-cyan-300" to={to}>{name}</Link>
                        ))}
                    </div>

                    { /* hamburger button */}

                    <div className={`md:hidden ${expanded ? "open" : ""}`}>

                        <button
                            id="menu-btn"
                            type="button"
                            className="z-40 block hamburger w-full  "
                            onClick={() => setExpanded(!expanded)}
                        >
                            <span className="hamburger-top" />
                            <span className="hamburger-middle" />
                            <span className="hamburger-bottom" />
                        </button>
                    </div>

                </nav>

                { /* mobile menu */}

                <AnimatePresence>
                    {expanded && (
                        <motion.div
                            initial={{
                                height: 0,
                                opacity: 0
                            }}
                            animate={{
                                height: "auto",
                                opacity: 1,
                                transition: {
                                    duration: 0.2,
                                }
                            }}
                            exit={{
                                height: 0,
                                opacity: 0,
                                transition: {
                                    delay: 0.1,
                                    duration: 0.2,
                                }
                            }}
                            id="menu" className="menu menu-compact lg:menu-normal bg-base-100 max-w-full p-0 rounded-box ">
                            <ul>
                                {links.map(({ name, to, id }) => (
                                    <li key={id}><Link onClick={() => setExpanded(!expanded)} className="text-primary text-sm pl-5 p-2 hover:text-secondary hover:bg-gray-50" to={to}>{name}</Link></li>

                                ))}
                            </ul>
                        </motion.div>
                    )}

                </AnimatePresence>

            </div>
        </>
    )
};

export default Nav;




