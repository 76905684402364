import { initializeApp, getApp } from "firebase/app";
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth } from 'firebase/auth'
import { getRemoteConfig } from "firebase/remote-config";
import configData from '../config/default.json'





// Initialize Firebase
let firebaseConfig = {};


if (process.env.REACT_APP_ENV === 'dev') {
  firebaseConfig = configData.firebase_dev
} else if (process.env.REACT_APP_ENV === 'staging') {
  firebaseConfig = configData.firebase_staging
} else if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = configData.firebase_production
} else {
  firebaseConfig = configData.firebase_dev
}

// console.log('in FirebaseClient.js')
// console.log('firebaseConfig', firebaseConfig)

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

// Enable offline persistence
if (process.env.NODE_ENV === 'production') {
  enableIndexedDbPersistence(db)
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        console.log('persistence failed')
      } else if (err.code === 'unimplemented') {
        console.log('persistence is not available')
      }
    });
}

const auth = getAuth();

console.table(process.env)
if (process.env.REACT_APP_LOCAL_FUNCTIONS === 'true') {
  console.log('****** emulator running with local functions ******')
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
  "wordpress_blog_url": "https://www.nickstaggtherapy.com/wp-json/wp/v2/posts?per_page=10"
};

if (process.env.REACT_APP_ENV === 'dev') {
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
}

export { db, auth, remoteConfig };