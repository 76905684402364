import React from 'react'
import { auth } from '../firebase/FirebaseClient'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import SimpleBtn from './ui/SimpleBtn';

export default function LogoutButton() {
  const navigate = useNavigate();

  const logout = () => {
    signOut(auth)
      .then(() => {
        // window.location.reload(false);
        navigate("/");
      })
      .catch((err) => {
        console.log(err.message)
      })
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    logout()
  }

  // TODO : make hover text white

  return (
    <>
      <div className="text-center pb-5 pt-5">
        <form onSubmit={handleSubmit}>
          <SimpleBtn type="submit" text="Sign Out" clickHandler={() => { handleSubmit() }} />
        </form>
      </div>
    </>
  )
}
