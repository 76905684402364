import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function SubmitTextModal({
  handleClose,
  handleText,
  headingText,
  placeholderText,
  bodyText,
  textMax,
  text,
}) {
  const schema = yup.object().shape({
    modalText: yup
      .string()
      .max(textMax, `${placeholderText} must be less than 200 characters`)
      .required(`${placeholderText} is required`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (formData) => {
    handleText(formData.modalText);
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="checkbox" id="" className="modal-toggle" />
      <label className="modal modal-open ">
        <label className="modal-box relative show-modal max-w-none w-full text-center">
          <label
            onClick={() => {
              handleClose(false);
            }}
            className="btn btn-sm btn-circle absolute right-1 top-1"
          >
            ✕
          </label>
          <h3 className="text-sm font-bold  p-4">{headingText}</h3>
          <textarea
            className="textarea textarea-bordered"
            placeholder={placeholderText}
            defaultValue={text}
            {...register("modalText")}
          ></textarea>
          <p className="text-red-500 pb-2">{errors.modalText?.message}.</p>
          <p className="text-sm p-4">{bodyText}</p>
          <button type="submit" className="btn btn-primary text-white">
            send
          </button>
        </label>
      </label>
    </form>
  );
}
