import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { db } from '../firebase/FirebaseClient'
import { doc, getDoc } from "firebase/firestore";
import ReactMarkdown from 'react-markdown'
import BadgeSizeable from './BadgeSizeable'
import './IndexDetails.css'
import configData from '../config/default.json'
import PageLoading from "./PageLoading";
import PageBanner from "./card/PageBanner";
import UserStatus from "./UserStatus";
import { useSelector } from "react-redux";
import CustomLinks from "./ui/CustomLinks";

import { motion } from "framer-motion";

export default function IndexDetail({ indexData, urlParams }) {

    const pageMotion = configData.pageMotion

    const collectionName = configData.collectionName;
    const [parentID, setParentID] = useState(null)
    const [articleData, setarticleData] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [userPremium, setUserPremium] = useState(false)
    const [freeContent, setFreeContent] = useState(false)

    const { premium } = useSelector((state) => state.premium);

    if (urlParams !== parentID) {
        setParentID(urlParams)
    }

    let childLinkObects = []
    let detail = null

    useEffect(() => {

        if (premium === true) {
            if (parentID) {
                setIsPending(true)
                const wellbeingDocRef = doc(db, collectionName, parentID)
                const getWellbeingDocData = async () => {
                    const docSnap = await getDoc(wellbeingDocRef);
                    if (docSnap.exists()) {
                        setarticleData(docSnap.data().content)
                        setIsPending(false)
                    } else {
                        console.log('Error : IndexDetail - no doc index found!')
                        setIsPending(false)
                    }
                }
                getWellbeingDocData()
            }
        }
        window.scrollTo(0, 0)
    }, [parentID, collectionName, premium])

    useEffect(() => {
        setUserPremium(premium)

        if (!premium) {
            const found = JSON.parse(indexData).find(element => element.id === urlParams)
            setFreeContent(found.excerpt)
        }


    }, [premium, indexData, urlParams])


    const indexJSONData = JSON.parse(indexData)
    detail = indexJSONData.find(o => o.id === urlParams);
    const children = detail.children

    children.forEach(element => {
        let childDetail = indexJSONData.find(o => element === o.documentName)

        childLinkObects.push({
            id: childDetail.id,
            title: childDetail.title,
            descendants: childDetail.descendants

        })
    });





    if (indexData === "") {
        return <div className="error">No indexDetail to load...</div>
    }

    function compareTitle(a, b) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}
        >

            { /* container */}
            <div className="m-5">


                { /* card - page banner */}
                <PageBanner text={detail.title} c />

                {!freeContent &&
                    <div className="pt-8">
                        <UserStatus />
                    </div>
                }

                {!userPremium && freeContent && (
                    <div className="pt-10">
                        <div className=" flex flex-row mx-auto justify-between items-center">
                            <div></div>
                            <article className=" text-center prose lg:prose-xl">
                                {freeContent} ...
                            </article>
                            <div></div>
                        </div>
                        <div className="pt-5 font-light text-center">Subscribe to read more</div>

                        <div className="pt-5">
                            <UserStatus />
                        </div>
                    </div>
                )}

                {userPremium && (articleData && (
                    <div className="bg-card-white-opaque p-3">
                        {isPending && <PageLoading />}
                        {!isPending && userPremium &&

                            <div className=" flex flex-row mx-auto justify-between items-center">
                                <div></div>
                                <article className="prose lg:prose-xl">
                                    <ReactMarkdown
                                        className='react-markDown'
                                        children={articleData}
                                        components={{
                                            a: ({ node, ...props }) => <CustomLinks {...props} />
                                        }}
                                    />
                                </article>
                                <div></div>
                            </div>

                        }
                    </div>
                ))}

                <div className="flex flex-wrap  justify-center p-10">
                    {childLinkObects.sort(compareTitle).map(item => (
                        <Link className="" to={`/wellbeing/${item.id}`} onClick={() => setParentID(item.id)} key={item.id}>
                            <BadgeSizeable sizeOfBadge={item.descendants} titleOfBadge={item.title} />
                        </Link>
                    ))}
                </div>

            </div>
        </motion.div>
    )
}
