import PuffLoader from "react-spinners/PuffLoader";

export default function PageLoading() {

  return (
    <div className="flex justify-center pt-10">
    <PuffLoader size={50} color="#C86FCE" />
    </div>
  )
}
