import { useParams } from "react-router-dom";
import { useState, useEffect } from "react"
import React from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { motion } from "framer-motion";
import configData from '../config/default.json'

export default function BlogEntry({ entry }) {

  const pageMotion = configData.pageMotion

  const { id } = useParams()
  const [found, setFound] = useState(null)
  const [title, setTitle] = useState(null)
  const [localBlogs] = useLocalStorageState('blogs', {
    ssr: true,
    defaultValue: {}
  })

  useEffect(() => {

    const localBlog = localBlogs.data.find(item => item.id === parseInt(id))
    setTitle(localBlog.title.rendered)
    setFound(localBlog.content.rendered)
    window.scrollTo(0, 0)

  }, [id, localBlogs.data])

  return (

    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >

      { /* container */}
      <div className="m-5">
        <div className="text-center bg-card-semi-opaque pt-5">
          <div className="circular_image ">
            <img src="/cubeV2colorTop.svg" alt="cube logo" />
          </div>
          <div className="p-10">
            <div className="text-2xl font-bold text-primary"
              dangerouslySetInnerHTML={{
                __html: title
              }}>
            </div>
          </div>
        </div>

        { /* container */}
        <div className="my-5">
          <div className="row gy-3">
            <div className="col-lg-6 ">
              <div className="card border-0 bg-card-semi-opaque">
              </div>

              <div className=" flex flex-row mx-auto justify-between items-center">
                <div></div>
              <article className="prose lg:prose-xl">
                <div className='react-markDown'
                  dangerouslySetInnerHTML={{
                    __html: found
                  }}></div>
              </article>
              <div></div>
              </div>


            </div>
          </div>
        </div>

      </div>
    </motion.div>

  )
}

