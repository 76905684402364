import React from "react";
import { motion } from "framer-motion";

export default function SimpleBtn({ text, clickHandler }) {
  return (
    <motion.button
      whileHover={{
        scale: 1.1,
      }}
      whileTap={{ scale: 0.9 }}
      onClick={() => clickHandler()}
    >
      <div className="btn btn-outline btn-primary">
        <div className="text-primary hover:text-white pl-5 pr-5 pt-3 pb-3">{text}</div>
      </div>
    </motion.button>
  );
}
