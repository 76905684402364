import React from "react";

export default function SubmitActionModal({
  handleClose,
  handleAction,
  headingText,
  actionText,
  bodyText,
}) {
  
  return (
    <>
      <input type="checkbox" id="" className="modal-toggle" />
      <label className="modal modal-open ">
        <label className="modal-box relative show-modal max-w-none w-full text-center">
          <label
            onClick={() => {
              handleClose(false);
            }}
            className="btn btn-sm btn-circle absolute right-1 top-1"
          >
            ✕
          </label>
          <h3 className="text-sm font-bold  p-4">{headingText}</h3>

          <p className="text-sm p-4">{bodyText}</p>
          <button
            onClick={() => {
              handleAction();
            }}
            className="btn btn-primary text-white"
          >
            {actionText}
          </button>
        </label>
      </label>
    </>
  );
}
