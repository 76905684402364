import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import { createCheckoutSession } from "../stripe/createCheckoutSession"
import LoginButton from './LoginButton';
import configData from '../config/default.json'
import './userStatus.css'
import { useDispatch } from 'react-redux'
import SimpleBtn from "./ui/SimpleBtn";

import { set_subscribed } from '../redux-toolkit/userPremiumSlice'
import { set_logged_in } from '../redux-toolkit/userLoggedInSlice'

// import LogRocket from 'logrocket';
// import { CreateUserConfig } from "./ui/createUserConfig";

export default function UserStatus() {



  let stripeRoleString = configData.stripe_role_dev

  if (process.env.REACT_APP_ENV === 'dev') {
    stripeRoleString = configData.stripe_role_dev
  } else if (process.env.REACT_APP_ENV === 'staging') {
    stripeRoleString = configData.stripe_role_staging
  } else if (process.env.REACT_APP_ENV === 'production') {
    stripeRoleString = configData.stripe_role_production
  } else {
    stripeRoleString = configData.stripe_role_dev
  }

  const navigate = useNavigate();
  const [userID, setUserID] = useState(null)
  const [premiumStatus, setPremiumStatus] = useState(false)
  const auth = getAuth();
  const user = auth.currentUser;
  const dispatch = useDispatch()

  const handleCreateCheckoutSession = (e, uid) => {
    // e.preventDefault()
    createCheckoutSession(uid)
  }


  useEffect(() => {

    onAuthStateChanged(auth, (user) => {
      // console.log('***stripeRoleString*** : [', stripeRoleString, ']')
      if (user) {
        dispatch(set_logged_in(true))

        setUserID(user.uid)

        user.getIdTokenResult(true).then(function (idToken) {

          if ( (idToken.claims.stripeRole === stripeRoleString) || (idToken.claims.acme === true) ) {
            setPremiumStatus(true)
            dispatch(set_subscribed(true))
          } else {
            setPremiumStatus(false)
            dispatch(set_subscribed(false))
          }
        })
      } else {
        dispatch(set_logged_in(false))
        navigate("/login")
      }
    });
  }, [auth, dispatch, navigate, stripeRoleString]);

  return (
    <>
      {!userID && <LoginButton />}

      {((!premiumStatus) && userID) && (
        <div className="text-center pb-5">
          <SimpleBtn type="submit" text="subscribe" clickHandler={(e) => { handleCreateCheckoutSession(e, user.uid) }} />
        </div>
      )}


    </>)
}