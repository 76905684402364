import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from '../firebase/FirebaseClient'
import { BsGoogle } from "react-icons/bs";
import { motion } from "framer-motion";

export default function GoogleLoginButton() {

  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const provider = new GoogleAuthProvider();

  const handleSubmit = (e) => {
    e.preventDefault()

    signInWithPopup(auth, provider)
      .then((result) => {
        navigate("/");
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        console.log('ERROR : ', errorCode, errorMessage, email)
        setLoginError(`please try another login method`)
      });
  }

  return (
    <>
      <div className="text-center pb-5 pt-5">
        {loginError &&
          <div className="text-red-500">{loginError} </div>
        }
      </div>
      <div className="text-center pb-5 pt-5">
        <form onSubmit={handleSubmit}>

          <motion.button
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
          >
            <div className="btn btn-outline btn-primary">
              <div className="flex flex-row pl-5 pr-5 pt-3 pb-3"><div className=""><BsGoogle /></div><div className="pl-1 hover:text-white">{"Sign in with Google"}</div></div>
            </div>
          </motion.button>

        </form>
      </div>
    </>
  )
}
