import React from 'react'
import { Link } from "react-router-dom";

export default function CustomLinks(props) {
    return (
        <>
            {
                props.href.startsWith("http") ?
                    (
                        <span className="font-bold">
                            {props.children[0]}
                        </span>
                    )
                    :
                    (<Link to={props.href} >
                        {props.children[0]}
                    </Link>
                    )
            }
        </>
    )
}
