import React, { useState, useEffect } from 'react'
// import GithubLoginButton from '../components/GithubLoginButton';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import SimpleBtn from '../components/ui/SimpleBtn';
// import { Form, InputGroup } from "react-bootstrap";
import { sendPasswordResetEmail } from 'firebase/auth'
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification
} from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import './Login.css'

import PageBanner from '../components/card/PageBanner';
import GoogleLoginButton from '../components/GoogleLoginButton';

export default function Login() {

    const navigate = useNavigate();

    const [loginError, setLoginError] = useState("");
    const [formDisabled, setFormDisabled] = useState(false);
    const [formState, setFormState] = useState(1)
    const auth = getAuth();
    const user = auth.currentUser;

    const formStateLookup = ['Login', 'Create New Account', 'Reset Password']

    const schema = yup.object().shape({
        email: yup.string().email("must be a valid email - also check there are no spaces").required("email is required"),
        terms: yup.boolean().oneOf([true], "you must agree to the terms and conditions")
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const onSubmit = (formData) => {

        if (formState === 1) {

            // LOGIN
            if (user?.emailVerified) {
                navigate("/");
            }

            signInWithEmailAndPassword(auth, formData.email, formData.password)
                .then((cred) => {
                    setLoginError("")
                    navigate("/");
                })
                .catch((err) => {
                    console.log(err.message)
                    setLoginError("account does not exist or password is incorrect")
                })
        } else if (formState === 2) {

            // CREATE ACCOUNT

            setLoginError('')
            createUserWithEmailAndPassword(auth, formData.email, formData.password)
                .then((cred) => {
                    sendEmailVerification(auth.currentUser)
                        .then(() => {
                            console.log('SENT EMAIL')
                        }).catch((err) => {
                            console.log('ERROR sending email : ', err)
                            setLoginError(err.message)
                        });

                    setFormState(1)
                    navigate("/login");
                })
                .catch((err) => {
                    console.log(err.code)
                    setLoginError(err.code)
                })
            setLoginError("email sent - check your email - be sure to check spam folders, then log in again ...")
        } else if (formState === 3) {

            // RESET PASSWORD

            sendPasswordResetEmail(auth, formData.email)
                .then(() => {
                    setLoginError("email sent - check your email and be sure also to look at the spam folder just in case ...")
                })
                .catch((err) => {
                    setLoginError('Error sending email, please try again later')
                })
        }
    }

    const handleEmailConfirmation = () => {
        sendEmailVerification(auth.currentUser)
            .then(() => {
                console.log('SENT EMAIL')
                setLoginError("email has been sent again ! - remember to check your email and be sure to check spam folders, then log in again ...")
            }).catch((err) => {
                console.log('ERROR sending email : ', err)
            });
    }

    const handleTermsAndConditions = () => {
        navigate("/tsandcs");
    }



    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        if (isCheckbox) {
            // console.log('checkbox checked ...')
        }

    }

    useEffect(() => {
        const onPageLoad = () => {
            setFormDisabled(false);
        };

        // Check if the page has already loaded
        if (document.readyState === "complete") {
            onPageLoad();
        } else {
            window.addEventListener("load", onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener("load", onPageLoad);
        }
    }, []);


    const handleCreateUser = () => {
        setLoginError("")
        setFormState(2)
    }

    const handleResetPassword = () => {
        setLoginError("")
        setFormState(3)
    }


    return (
        <>
            { /* container */}
            <div className="m-5">


                { /* card - page banner */}
                <PageBanner text={`${formStateLookup[formState - 1]}`} />




                <div className="flex flex-col pb-5">
                    <div className="row gy-3">
                        <div className=" text-center pb-5 pt-5">


                            {user && (
                                <div className='p-5'>
                                    <p className='p-5'>please verify your email </p>
                                    <p className='p-5'> if you did not recive an email click below to resend</p>
                                    <SimpleBtn text="resend" clickHandler={() => { handleEmailConfirmation() }} />

                                </div>
                            )}


                            {loginError &&
                                <p className='text-red-500 pb-4'>{loginError}</p>
                            }

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <>
                                    { /* input for email */}
                                    <div className='mb-3'>
                                        <input
                                            type="text"
                                            placeholder="email"
                                            className="input input-bordered w-full max-w-xs"
                                            id='user-email-input'
                                            onChange={handleChange}
                                            disabled={formDisabled}
                                            {...register("email")}
                                        />
                                    </div>

                                </>
                                <p className="text-red-500 pb-3">{errors.email?.message}</p>

                                {formState !== 3 && (
                                    <>
                                        { /* input for password */}
                                        <div className='mb-3'>
                                            <input
                                                type="password"
                                                placeholder="password"
                                                className="input input-bordered w-full max-w-xs"
                                                id='user-password-input'
                                                onChange={handleChange}
                                                disabled={formDisabled}
                                                {...register("password")}
                                            />
                                        </div>
                                    </>

                                )}
                                <p className="text-red-500 pb-3">{errors.password?.message}</p>

                                {formState === 2 && (
                                    <>
                                        { /* input group for terms */}
                                        <div className='mb-3'>
                                            { /* form check */}

                                            <p>accept terms & conditions</p>
                                            <input
                                                type="checkbox"
                                                className="checkbox checkbox-primary"
                                                name='terms'
                                                id='accept-terms'
                                                label="Accept Terms"
                                                disabled={formDisabled}
                                                {...register("terms")}
                                            />
                                        </div>

                                    </>
                                )}
                                <p className="text-red-500 pb-3">{errors.terms?.message}</p>

                                {formState === 1 && (

                                    <>
                                        <SimpleBtn text="login" clickHandler={() => { setFormState(1) }} />
                                    </>
                                )}
                                {formState === 2 && (
                                    <>
                                        <SimpleBtn text="terms & conditions" clickHandler={() => { handleTermsAndConditions() }} />
                                        <div className='pb-5'></div>
                                    </>
                                )}
                                {formState === 2 && (

                                    <SimpleBtn text="create" clickHandler={() => { }} />

                                )}
                                {formState === 3 && (

                                    <SimpleBtn text="send email" clickHandler={() => { setFormState(3) }} />


                                )}
                            </form>

                            {formState === 1 && (
                                <>
                                    <div className='pt-5'>
                                        <p className='text-center'>or</p>
                                    </div>
                                    <div className='pt-5'>
                                        <SimpleBtn text="Sign Up" clickHandler={() => (handleCreateUser())} />
                                    </div>
                                </>
                            )}
                            {formState === 1 && (
                                <>
                                    <div className='text-center'>
                                        <p className='pt-5'>forgot password ?</p>
                                    </div>
                                    <div className='pt-5'>
                                        <SimpleBtn text="reset" clickHandler={() => handleResetPassword()} />
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>
                <hr className='pt-1' />
                {formState === 1 && (
                    <div className='pt-5 text-center'>
                        <p>or</p>
                    </div>
                )}
                {!user && formState === 1 && (
                    <GoogleLoginButton />
                )}

            </div>
        </>
    )
}
