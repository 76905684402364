import React from 'react'
import { Link } from 'react-router-dom'
import SimpleBtn from './ui/SimpleBtn'

export default function LoginButton() {

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <div className="text-center pb-5 pt-5">
        <form onSubmit={handleSubmit}>
          <Link to="/login">

            <SimpleBtn  type="submit" text="Sign In" clickHandler={() => {handleSubmit()}} />


          </Link>
        </form>
      </div>
    </>
  )
}