import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  results: null,
}

export const searchSlice = createSlice({
  name: "searcher",
  initialState,
  reducers: {
    storeSearch: (state, action) => {
      state.results = action.payload;
    }
  }
});

export const { storeSearch } = searchSlice.actions

export default searchSlice.reducer;