import { useEffect, useState } from "react";
import { db } from "../firebase/FirebaseClient";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { signOut, getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import configData from "../config/default.json";
import SimpleBtn from "./ui/SimpleBtn";
import SubmitActionModal from "./ui/SubmitActionModal";
import PageLoading from "./PageLoading";
import UserStatus from "./UserStatus";

export default function SubscriptionStatus() {
  const functions = getFunctions(getApp());
  const auth = getAuth();
  const user = auth.currentUser;
  const [subscription, setSubscrption] = useState(null);
  const [subscriptionDate, setSubscrptionDate] = useState(null);
  const [showCancel, setShowCancel] = useState(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(null);
  const [userPremium, setUserPremium] = useState(false);

  const { premium } = useSelector((state) => state.premium);

  const [content, setContent] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const wellbeingDocRef = doc(
      db,
      configData.collectionName,
      configData.subscriptionmanagement
    );
    getDoc(wellbeingDocRef)
      .then((doc) => {
        const result = doc.data().content.split(/---*/);
        setContent(result);
      })
      .catch((error) => {
        console.log("no doc index found : ", error);
      });
    if (user) {
      // TODO: remove nasty code duplication of getSubscriptionDoc
      const getSubscriptionDoc = async (userID) => {
        const collRef = collection(db, "users", userID, "subscriptions");
        const collections = await getDocs(collRef);
        if (collection.length > 0) {
          collections.forEach((doc) => {
            setSubscrption(doc.id);
            setSubscrptionDate(formatDate(doc.data().created.seconds));
          });
        }
      };
      getSubscriptionDoc(user?.uid);
    }

    window.scrollTo(0, 0);
  }, [user]);

  useEffect(() => {
    setUserPremium(premium);
  }, [premium]);

  onAuthStateChanged(auth, (user) => {
    // TODO: remove nasty code duplication of getSubscriptionDoc
    const getSubscriptionDoc = async (userID) => {
      const collRef = collection(db, "users", userID, "subscriptions");
      const collections = await getDocs(collRef);
      if (collection.length > 0) {
        collections.forEach((doc) => {
          setSubscrption(doc.id);
          setSubscrptionDate(formatDate(doc.data().created.seconds));
        });
      }
    };

    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User

      if (!subscription) {
        getSubscriptionDoc(user.uid);
      }
    } else {
      setCancelStatus("You are not logged in");
    }
  });

  const formatDate = (epoch) => {
    var date = new Date(epoch * 1000);
    var formattedDate =
      date.getUTCDate() +
      "/" +
      (date.getUTCMonth() + 1) +
      "/" +
      date.getUTCFullYear();
    return formattedDate;
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleCancel = () => {

    setShowCancel(false);
    setCancellingSubscription(true);

    const cancelSubscription = httpsCallable(functions, "unsubscribe");

    cancelSubscription()
      .then((result) => {

        if (result.data) {

          if (result.data.status === true) {
            setCancellingSubscription(false);
            setCancelStatus("cancelled");
            logout();
          } else {
            console.log("error");
            setCancelStatus("error");
          }
        }
      })
      .catch((error) => {
        console.log("failed : ", error);
        setCancelStatus("error");
      });
  };

  const handleShowCancel = function () {
    setShowCancel(!showCancel);
  };

  return (
    <>
      {!userPremium && (
        <div className="pt-10">
          <div className="pt-5 font-light text-center">
            Subscribe to get full access
          </div>

          <div className="pt-5">
            <UserStatus />
          </div>
        </div>
      )}

      {subscription && (
        <>
          <div className="pt-10 text-xs">{subscription}</div>
          <div className="pb-10">{subscriptionDate}</div>
        </>
      )}
      {subscription && cancelStatus === null && (
        <SimpleBtn
          text="Cancel Subscription"
          clickHandler={() => {
            handleShowCancel();
          }}
        />
      )}
      {cancellingSubscription && <PageLoading className="pt-5" />}
      {cancelStatus && (
        <>
          <div className="pt-5">{cancelStatus}</div>
          <UserStatus />
        </>
      )}

      {showCancel && (
        <SubmitActionModal
          handleClose={handleShowCancel}
          handleAction={handleCancel}
          headingText="Cancel Subscription"
          actionText="Cancel Subscription Now"
          bodyText={content[2]}
        />
      )}
    </>
  );
}
