import { useEffect, useState } from "react";
import { db } from '../firebase/FirebaseClient'
import { doc, getDoc } from "firebase/firestore";
import ReactMarkdown from 'react-markdown'
import configData from '../config/default.json'
import { motion } from "framer-motion";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import PageBanner from "../components/card/PageBanner";
import Toggle from "../components/ui/Toggle";
import UserStatus from "../components/UserStatus";
import CustomLinks from "../components/ui/CustomLinks";

export default function Tips() {

  const pageMotion = configData.pageMotionMenu
  const [articleData, setarticleData] = useState(null)
  const [userPremium, setUserPremium] = useState(false)

  const { premium } = useSelector((state) => state.premium);

  const auth = getAuth();
  const navigate = useNavigate();


  useEffect(() => {

    const wellbeingDocRef = doc(db, configData.partials, configData.tips)
    const getTipsData = async () => {
      const docSnap = await getDoc(wellbeingDocRef);
      if (docSnap.exists()) {
        setarticleData(docSnap.data().content)
      } else {
        console.log("ERROR : Tips - No document found!");
      }
    }
    getTipsData()

    window.scrollTo(0, 0)
    const savedItem = localStorage.getItem("tips");
    const tips = JSON.parse(savedItem);
    if (tips === null) {
      localStorage.setItem("tips", true);
    }

  }, [])

  useEffect(() => {
    setUserPremium(premium)
  }, [premium])


  onAuthStateChanged(auth, (user) => {
    if (!user) {
      navigate("/login");
    }
  });

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      { /* container */}
      <div className="m-5">



        { /* card - page banner */}
        <PageBanner text={"Welcome"} />

        <Toggle />

        {!userPremium && (
          <div className="pt-10">

            <div className="pt-5 font-light text-center">Subscribe to get full access</div>

            <div className="pt-5">
              <UserStatus />
            </div>
          </div>
        )}

        { /* article content */}
        <div className="bg-card-white-opaque p-3 text-center">
          {articleData && (
            <div className="bg-card-white-opaque p-3">
              <div className=" flex flex-row mx-auto justify-between items-center">
                <div></div>
                <article className="prose lg:prose-xl">
                  <div className="text-center">
                    <ReactMarkdown
                      className='react-markDown'
                      children={articleData}
                      components={{
                        a: ({ node, ...props }) => <CustomLinks {...props} />
                      }}
                    />
                  </div>
                </article>
                <div></div>
              </div>
            </div>
          )}







        </div>


      </div>
    </motion.div >
  );
}
