

import React from 'react'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import useLocalStorageState from 'use-local-storage-state'
import { motion } from "framer-motion";

import configData from '../config/default.json'
import PageBanner from '../components/card/PageBanner';

export default function Blog() {

    const pageMotion = configData.pageMotion

    const [blogs, setBlogs] = useState(null)
    const [localBlogs, setLocalBlogs] = useLocalStorageState('blogs', {
        ssr: true,
        defaultValue: {}
    })

    // const wordpress_blog_url = getValue(remoteConfig, "wordpress_blog_url");


    useEffect(() => {
        let age
        const secondsSinceEpoch = Math.round(Date.now() / 1000)
        age = secondsSinceEpoch - localBlogs.epoch

        let blogs = false
        if (localBlogs.data) {
            blogs = true
        } else {
            console.log('ERROR : we have no data')
        }
        if (!blogs) {
            console.log("ERROR : NO DATA")
        }
        if (!blogs || age > configData.time_to_cache_blogs) {

            fetch(configData.wordpress_blog_url)
                .then((response) => response.json())
                .then((data) => {
                    setBlogs(data);
                    const secondsSinceEpoch = Math.round(Date.now() / 1000)
                    setLocalBlogs({
                        epoch: secondsSinceEpoch,
                        data: data
                    })

                });
        } else {
            setBlogs(localBlogs.data)
        }
    }, [localBlogs.data, localBlogs.epoch, setLocalBlogs]);
    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}
        >
            { /* container */}
            <div className="m-5">


                { /* card - page banner */}
                <PageBanner text={"Blog"} />

                <div className="container-lg my-5">
                    <div className="row gy-3">
                        {/* list blog entries */}
                        {blogs && blogs.map(item => (
                            <div key={item.id} className="col-lg-6 ">
                                <Link to={`/blogentry/${item.id}`}>

                                <div className="card border-0 bg-card-semi-opaque">

                                    <div className="card-body text-center py-5">
                                        <div
                                            className="text-secondary "
                                            dangerouslySetInnerHTML={{
                                                __html: item.title.rendered
                                            }}></div>
                                    </div>
                                </div>


                                <div className=" flex flex-row mx-auto justify-between items-center">
                                    <div></div>
                                    <article className="prose lg:prose-xl">
                                        <div
                                            className='text-center font-normal'
                                            dangerouslySetInnerHTML={{
                                                __html: item.excerpt.rendered
                                            }}></div> 
                                    </article>
                                    <div></div>
                                </div>




                                <div className='pb-10 font-light text-center'>Read More ...</div>
                            </Link>
                            </div>
                        ))}
                </div>
            </div>

        </div>
        </motion.div >
    )
}
