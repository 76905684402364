import { React } from "react";
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { storeSearch } from '../redux-toolkit/searchSlice'

export default function SearchResults() {
  const dispatch = useDispatch()
  const search = useSelector((state) => state.searcher.results);
  if (search === null || search.length === 0) {
    return (<></>)
  }
  return (
    <div>
      <div className="container-lg my-5">
        <div className="text-center pb-5">
        </div>
        {search && search.map(result =>
          <p key={result.refIndex}>
            <Link className="link-secondary" onClick={() => dispatch(storeSearch(null))} to={`/wellbeing/${result.item.id}`}>
              {result.item.title}
            </Link>
          </p>
        )}
      </div>
    </div>
  )
}
