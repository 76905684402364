import React from 'react'
import configData from '../config/default.json'
import { motion } from "framer-motion";

import LogoutButton from '../components/LogoutButton';
import PageBanner from '../components/card/PageBanner';

export default function Logout() {
  const pageMotion = configData.pageMotionMenu
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      {/* container */}
      <div className="m-5">

        { /* card - page banner */}
        <PageBanner text={"Logout"} />

        <div className="bg-card-white-opaque p-3 text-center">
          <LogoutButton />
        </div>

      </div>
    </motion.div>
  )
}
