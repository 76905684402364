import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
}

export const searchTextSlice = createSlice({
  name: "searchtext",
  initialState,
  reducers: {
    storeSearchText: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { storeSearchText } = searchTextSlice.actions

export default searchTextSlice.reducer;