import { useEffect, useState } from "react";
import { db } from '../firebase/FirebaseClient'
import { doc, getDoc } from "firebase/firestore";
import CustomLinks
 from "./ui/CustomLinks";
import ReactMarkdown from 'react-markdown'
import configData from '../config/default.json'

export default function FAQs() {

    const [articleData, setarticleData] = useState(null)

    useEffect(() => {

        const wellbeingDocRef = doc(db, configData.partials, configData.faq)
        const getFAQData = async () => {
            const docSnap = await getDoc(wellbeingDocRef);
            if (docSnap.exists()) {
                setarticleData(docSnap.data().content)
            } else {
                console.log("Error : FAQs - No document found!");
            }
        }
        getFAQData()

        window.scrollTo(0, 0)
    }, [])

    return (

        <>

            <div className="bg-card-white-opaque p-3 text-center">
                {articleData && (
                    <div className="bg-card-white-opaque p-3">
                        <div className=" flex flex-row mx-auto justify-between items-center">
                            <div></div>
                            <article className="prose lg:prose-xl">
                                <div className="text-center">
                                    <ReactMarkdown
                                        className='react-markDown'
                                        children={articleData}
                                        components={{
                                            a: ({ node, ...props }) => <CustomLinks {...props} />
                                        }}
                                    />
                                </div>
                            </article>
                            <div></div>
                        </div>
                    </div>
                )}
            </div>

        </>




    )
}
