import React from 'react'
import { motion } from "framer-motion";

import FAQs from '../components/FAQs';
import configData from '../config/default.json'
import PageBanner from "../components/card/PageBanner";

export default function FAQ() {

  const pageMotion = configData.pageMotionMenu

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      {/* container */}
      <div className="m-5">

        { /* card - page banner */}
        <PageBanner text={"Frequently Asked Questions"} />

        <div className="bg-card-white-opaque p-3 text-center">
          <FAQs />
        </div>

      </div>
    </motion.div>
  );
}
