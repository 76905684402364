import { useEffect, useState } from "react";
import { db } from "../firebase/FirebaseClient";
import { doc, getDoc } from "firebase/firestore";
import ReactMarkdown from "react-markdown";
import configData from "../config/default.json";
import FounderImage from "../images/nick_stagg_profile_pic_200.jpg";
import { motion } from "framer-motion";
import PageBanner from "../components/card/PageBanner";
import CustomLinks from "../components/ui/CustomLinks";

export default function Founder() {
  const pageMotion = configData.pageMotionMenu;

  const [articleData, setarticleData] = useState(null);
  if (!articleData) {
  }
  useEffect(() => {
    const wellbeingDocRef = doc(db, configData.partials, configData.founder);
    const getFounder = async () => {
      const docSnap = await getDoc(wellbeingDocRef);
      if (docSnap.exists()) {
        const result = docSnap.data().content.split(/---*/);
        setarticleData(result);
      } else {
        console.log("Error : Founder - No document found!");
      }
    };
    getFounder();

    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      {/* container */}
      <div className="m-5">
        {/* card - page banner */}
        <PageBanner text={"Founder"} />

        <img
          src={FounderImage}
          alt="Nick Stagg"
          className="mt-10 rounded-full mx-auto"
        />
        {articleData && (
          <div className=" flex flex-row mx-auto justify-between items-center">
            <div></div>
            <article className="prose lg:prose-xl">
              <div className="container-lg my-5">
                <ReactMarkdown
                  className="react-markDown"
                  children={articleData[2]}
                  components={{
                    a: ({ node, ...props }) => <CustomLinks {...props} />,
                  }}
                />
              </div>
            </article>
            <div></div>
          </div>
        )}
      </div>
    </motion.div>
  );
}
